var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{scopedSlots:_vm._u([(_vm.items.length)?{key:"default",fn:function(){return [_c('thead',{staticStyle:{"background-color":"#d6d6d6"}},[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Equipes")]),_c('th',{staticClass:"text-left"},[_vm._v("Colaboradores")]),(_vm.items[0].id_tipo_meta == 6)?_c('th',{staticClass:"text-left"},[_vm._v(" Individual ")]):_c('th',{staticClass:"text-left"},[_vm._v("Progressiva")])])]),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.nome_equipe))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.total_colaboradores))]),(_vm.items[0].id_tipo_meta == 6)?_c('td',[(_vm.onEdite)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.vincularColaborador(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-account")])],1)]}}],null,true)},[_c('span',[_vm._v("Vincular Colaborador"),_c('br'),_vm._v("a Campanha")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.listarColaborador(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-search")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar Colaboradores")])])],1):_c('td',[(_vm.onEdite)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.vincularColaboradorProgressiva(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-account")])],1)]}}],null,true)},[_c('span',[_vm._v("Adicionar Faixa")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.listarColaboradorProgressiva(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-search")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar Faixas")])])],1)])}),0)]},proxy:true}:null],null,true)}),_c('DraggableColaboradorCampanha',{attrs:{"dialog":_vm.dialog,"dadosCampanha":_vm.campanha,"colaboradoresNaoParticipantes":_vm.colaboradoresNaoParticipantes,"colaboradoresParticipantes":_vm.colaboradoresParticipantes},on:{"close":_vm.closeModal,"change:fecthEquipes":function($event){return _vm.vincularColaborador($event)}}}),_c('ModalColaboradoresCampanha',{attrs:{"items":_vm.colaboradoresParticipantes,"dialog":_vm.dialogList},on:{"close":function($event){_vm.dialogList = false}}}),_c('DraggableColaboradorCampanhaProgressiva',{attrs:{"dialog":_vm.dialogProgressivo,"campanha":_vm.campanha,"colaboradoresNaoParticipantesProgressiva":_vm.colaboradoresNaoParticipantesProgressiva,"colaboradoresParticipantesProgressiva":_vm.colaboradoresParticipantesProgressiva},on:{"close":_vm.closeModalProgressiva,"atualizaColaboradoresParticipantesProgressiva":_vm.atualizaColaboradoresParticipantesProgressiva}}),_c('ModalColaboradorCampanhaProgressiva',{attrs:{"items":_vm.colaboradoresParticipantesProgressiva,"dialog":_vm.dialogListProgressiva},on:{"close":function($event){_vm.dialogListProgressiva = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }