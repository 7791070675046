<template>
  <v-simple-table>
    <template v-slot:default v-if="loading">
      <tbody>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </tbody>
    </template>
    <template v-else v-slot:default>
      <thead style="background-color:#d6d6d6">
        <tr>
          <th class="text-left">Semanas</th>
          <th class="text-left">Valor Meta</th>
          <th class="text-left">Valor Bônus</th>
          <th class="text-left">Data Inicial</th>
          <th class="text-left">Data Final</th>
        </tr>
      </thead>
      <tbody v-if="items.length != 0">
        <tr v-for="(item, index) in items" :key="index">
          <td>Semana {{ index + 1 }}</td>
          <td>{{ item.valor_meta }}</td>
          <td>{{ item.valor_bonus | BrazilianCurrency }}</td>
          <td>{{ item.data_inicio | BrazilianStandardDate }}</td>
          <td>{{ item.data_final | BrazilianStandardDate }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5" class="text-center">Não há dados disponíveis</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import currencyMixin from "@/mixins/currencyMixin";
import dateMixin from "@/mixins/dateMixin";
import campanha from "@/services/http/campanhaService";
export default {
  name: "ListaSemanasCampanha",
  mixins: [currencyMixin, dateMixin],
  props: {
    idCampanhaFuncao: {
      type: Number
    }
  },

  data() {
    return {
      items: [],
      loading: false
    };
  },

  methods: {
    async fetchSemanasCampanha() {
      this.loading = true;
      try {
        const { data } = await campanha()
          .funcaoSemanal()
          .show({ id_campanha_funcao: this.idCampanhaFuncao });
        this.items = data.data.sort(
          (a, b) => new Date(a.data_inicio) - new Date(b.data_inicio)
        );
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao trazer semanas"
        });
      } finally {
        this.loading = false;
      }
    }
  },

  mounted() {
    this.fetchSemanasCampanha();
  }
};
</script>

<style></style>
