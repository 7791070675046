<template>
  <div>
    <div>
      <v-data-table
        :headers="headers"
        :items="getFuncaoCampanhaProgressiva"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="id_campanha_funcao"
        sort-by="cod_funcao"
        show-expand
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-card-title>Metas Progressivas</v-card-title>
            <v-spacer></v-spacer>
            <v-switch
              v-model="singleExpand"
              label="Abrir apenas um"
              class="mt-2"
            ></v-switch>
          </v-toolbar>
        </template>
        <template v-slot:[`item.funcao`]="{ item }">
          <LineTable
            class="mt-2 mb-2"
            :code="item.cod_funcao"
            :description="item.funcao"
            :segmento="item.id_band"
          />
        </template>

        <template v-slot:[`item.indicador`]="{ item }">
          <LineTable
            class="mt-2 mb-2"
            :code="item.id_indicador"
            :description="item.indicador"
          />
        </template>

        <template v-slot:[`item.valor_bonus`]="{ item }">
          <td>
            {{ item.valor_bonus | BrazilianCurrency }}
          </td>
        </template>
        <!-- Prioridade do indicador -->
        <template v-slot:[`item.maior_menor`]="{ item }">
          <span v-if="item.maior_menor === '>'">
            <v-icon class="mr-1" small color="green">mdi-arrow-up </v-icon>
            Maior
          </span>
          <span v-if="item.maior_menor === '<'">
            <v-icon class="mr-1" small color="blue">mdi-arrow-down </v-icon>
            Menor
          </span>
        </template>

        <!--status -->
        <template v-slot:[`item.status`]="{ item }">
          <v-tooltip
            top
            v-if="getFaixasMetaProgressiva(item.id_campanha_funcao).length <= 1"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="warning" v-bind="attrs" v-on="on"
                >mdi mdi-alert-circle
              </v-icon>
            </template>
            <span>Metas progressivas devem ter no mínimo duas faixas</span>
          </v-tooltip>
          <v-icon v-else color="success">mdi mdi-check</v-icon>
        </template>

        <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
          <v-btn color="primary" small @click="expand(!isExpanded)"
            >expandir</v-btn
          >
        </template>

        <!-- lista faxas progressivas -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <lista-faixas-progressivas
              :onEdite="onEdite"
              :items="getFaixasMetaProgressiva(item.id_campanha_funcao)"
              @delete="initialize()"
            />
          </td>
        </template>

        <!-- btn adiciona faixa -->
        <template v-if="onEdite" v-slot:[`item.actions`]="{ item }">
          <v-btn
            fab
            x-small
            color="green"
            dark
            class="mr-2"
            @click="createMetaProgressiva(item)"
          >
            <v-icon small>
              mdi-plus
            </v-icon></v-btn
          >
        </template>
      </v-data-table>
    </div>

    <!-- Modal de cadastro da faixa progressiva -->
    <v-dialog
      persistent
      v-model="dialog"
      :retain-focus="false"
      max-width="500px"
    >
      <v-card>
        <v-card-title>Adicionar faixa progressiva</v-card-title>
        <v-card-text>
          <form-meta-progressiva
            :onEdite="onEdite"
            :item="itemMetaProgressiva"
            @success="onSuccessCreateMetaProgressiva"
            @close="dialog = false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Modal de cadastro da faixa progressiva -->
  </div>
</template>
<script>
import LineTable from "@/components/shared/format/LineTable.vue";
import currencyMixin from "@/mixins/currencyMixin";
import { mapActions, mapGetters } from "vuex";
import FormMetaProgressiva from "./FormMetaProgressiva.vue";
import ListaFaixasProgressivas from "./ListaFaixasProgressivas.vue";
export default {
  props: {
    onEdite: {
      type: Boolean,
      default: true
    }
  },
  mixins: [currencyMixin],
  components: {
    ListaFaixasProgressivas,
    FormMetaProgressiva,
    LineTable
  },

  data() {
    return {
      expanded: [],
      singleExpand: false,

      dialog: false,
      itemMetaProgressiva: {}
    };
  },

  mounted() {
    this.initialize();
  },

  computed: {
    ...mapGetters({
      getFuncaoCampanhaProgressiva: "campanha/getFuncaoCampanhaProgressiva",
      getFaixasMetaProgressiva: "campanha/getFaixasMetaProgressiva",
      getStatusMetasPrograssivas: "campanha/getStatusMetasPrograssivas"
    }),

    headers() {
      let headers = [
        { text: "Função", value: "funcao" },
        { text: "Indicador", value: "indicador" },
        { text: "Base de Cálculo", value: "tipo_calculo" },
        { text: "Prioridade do Indicador", value: "maior_menor" },
        { text: "Status", value: "status" },
        { text: "", value: "data-table-expand" }
      ];

      if (this.onEdite) {
        headers.splice(headers.length - 1, 0, {
          text: "Ações",
          value: "actions"
        });
      }

      return headers;
    }
  },

  methods: {
    ...mapActions({
      fetchFaixasMetasProgressivas: "campanha/fetchFaixasMetasProgressivas"
    }),

    status() {
      return "<v-icon>mdi mdi-alert-circle </v-icon>";
    },
    async initialize() {
      try {
        await this.fetchFaixasMetasProgressivas(this.$route.params.idCampanha);
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Houve um erro ao buscar as metas progressivas desta campanha"
        });
      }
    },

    createMetaProgressiva(item) {
      this.dialog = true;
      this.itemMetaProgressiva = item;
    },

    onSuccessCreateMetaProgressiva() {
      this.dialog = false;
      this.itemMetaProgressiva = {};
      this.initialize();
    }
  }
};
</script>
