<template>
  <div>
    <p class="pa-0 ma-0 text-body-2 font-weight-black d-flex align-center">
      {{ code }}
      <BaseSegmento v-if="segmento !== ''" :segmento="segmento" class="ml-2" />
    </p>
    <p class="pa-0 ma-0">
      {{ description | pascalCase }}
    </p>
  </div>
</template>

<script>
import BaseSegmento from "@/components/shared/BaseSegmento";
import formatName from "@/mixins/formatName";
export default {
  mixins: [formatName],

  props: {
    code: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    segmento: {
      type: [String, Number],
      default: ""
    }
  },

  components: {
    BaseSegmento
  }
};
</script>
