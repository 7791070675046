<template>
  <v-dialog v-model="dialog" max-width="60%" scrollable persistent>
    <v-card>
      <v-card-title>
        <span class="headline">
          Vincular Modelo -
          <strong>{{ campanha.descricao_modelo }}</strong>
        </span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row>
          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="red">mdi-close</v-icon>Colaboradores
              Fora da Campanha
            </h4>
            <v-btn
              class="mb-3"
              color="success"
              @click="addTodosColaboradores"
              :disabled="
                metaColaborador == 0 ||
                  bonusColaborador == 0 ||
                  colaboradoresNaoParticipantes.length == 0
              "
            >
              Adicionar todos
            </v-btn>
            <draggable
              class="draggable-area exclude-persons"
              :list="colaboradoresNaoParticipantes"
              group="colaboradores"
              @change="moveAdded"
            >
              <div
                class="v-list-item__content list"
                v-for="colaborador in colaboradoresNaoParticipantes"
                :key="colaborador.id_colaborador_equipe"
              >
                <div class="list-colaborador-items">
                  <div class="list-equipe">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${colaborador.cod_funcao} -`
                      }}</strong>
                      {{ colaborador.nome || colaborador.colaborador }}
                    </v-row>
                    <BaseSegmento :segmento="colaborador.id_band" />
                  </div>
                  <div class="mr-4">
                    <v-btn
                      icon
                      @click="addColaboradoesParcitipantes(colaborador)"
                      :disabled="metaColaborador == 0 || bonusColaborador == 0"
                    >
                      <v-icon color="green">mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </draggable>
            <!-- <v-btn @click="adicionarTodasEquipes" class="mt-2" small
        >Adicionar Todas</v-btn
      > -->
          </v-col>

          <v-divider vertical></v-divider>

          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="green">mdi-check</v-icon>Colaboradores
              Participantes da Campanha
            </h4>
            <v-btn
              class="mb-3"
              color="error"
              @click="removerTodosColaboradores"
              :disabled="colaboradoresParticipantes.length == 0"
            >
              Remover todos
            </v-btn>
            <draggable
              class="draggable-area include-persons"
              :list="colaboradoresParticipantes"
              group="colaboradores"
              @change="moveRemove"
            >
              <div
                class="v-list-item__content"
                v-for="colaborador in colaboradoresParticipantes"
                :key="colaborador.id_colaborador"
              >
                <div class="list-equipe-participantes-items">
                  <div>
                    <v-btn
                      class="mr-4"
                      icon
                      @click="removeColaboradoresParticipantes(colaborador)"
                    >
                      <v-icon color="red">mdi-arrow-left-bold</v-icon>
                    </v-btn>
                  </div>
                  <div class="list-equipe mr-4">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${colaborador.cod_funcao} -`
                      }}</strong>

                      {{ colaborador.colaborador }} - Meta:
                      {{ colaborador.meta_campanha }}
                    </v-row>
                    <BaseSegmento :segmento="colaborador.id_band" />
                  </div>
                </div>
              </div>
            </draggable>
            <v-divider class="mt-5 mb-5"></v-divider>

            <v-row dense align="center">
              <v-col cols="12">
                <BaseSelect
                  :items="tiposIndices.items"
                  label="Tipo de Meta"
                  item-text="descricao"
                  item-value="id_tipo_indice"
                  :preSelected="tiposIndices.preSelected"
                  @change="
                    tiposIndices.selected = $event;
                    metaColaborador = 0;
                    bonusColaborador = 0;
                  "
                />
              </v-col>
              <v-col cols="6" class="mt-2">
                <vuetify-money
                  v-model.number="metaColaborador"
                  label="Meta do Colaborador"
                  valueWhenIsEmpty="0"
                  :options="optionsVuetifyMoney"
                />
              </v-col>
              <v-col cols="6" class="mt-2">
                <vuetify-money
                  v-model.number="bonusColaborador"
                  label="Bônus do Colaborador"
                  valueWhenIsEmpty="0"
                  :options="optionsVuetifyMoneyBonus"
                />
              </v-col>
              <v-col cols="12">
                <v-alert
                  v-if="metaColaborador == 0 || bonusColaborador == 0"
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                  Digite uma meta e um bonus para efetuar o vínculo de
                  colaboradores
                </v-alert>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseSegmento from "@/components/shared/BaseSegmento";
import BaseSelect from "@/components/shared/NewBaseSelect";
import serviceParametro from "@/services/parametros";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";

export default {
  name: "DraggableColaboradorCampanhaModelo",

  components: {
    draggable,
    BaseSegmento,
    BaseSelect
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    campanha: {
      type: Object
    },

    colaboradoresNaoParticipantes: {
      type: Array
    },

    colaboradoresParticipantes: {
      type: Array
    }
  },

  data() {
    return {
      metaColaborador: 0,
      bonusColaborador: 0,
      tiposIndices: {
        items: [],
        preSelected: {
          descricao: "Unidade",
          tipo_indice: "U",
          id_tipo_indice: 1
        },
        selected: 1
      },
      optionsVuetifyMoneyBonus: {
        locale: "pt-BR",
        prefix: "R$",
        length: 8,
        precision: 2
      }
    };
  },

  computed: {
    ...mapGetters({
      getCurrentCampanha: "campanha/getCurrentCampanha"
    }),

    seeDraggableAddColaboradores() {
      return this.metaColaborador >= 1;
    },

    optionsVuetifyMoney() {
      return {
        locale: "pt-BR",
        prefix: this.tiposIndices.selected == 1 ? "UN" : "R$",
        length: this.tiposIndices.selected == 1 ? 3 : 8,
        precision: this.tiposIndices.selected == 1 ? 0 : 2
      };
    }
  },

  methods: {
    async fetchTipoIndice() {
      try {
        const { data } = await serviceParametro.getTipoIndice();
        this.tiposIndices.items = data.data;
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "error",
          text: "Erro eo buscar indices"
        });
      }
    },

    addTodosColaboradores() {
      const promises = this.colaboradoresNaoParticipantes.map(item => {
        const form = {
          id_empresa: item.id_empresa,
          id_campanha: this.$route.params.idCampanha,
          id_funcao: item.id_funcao,
          id_colaborador: item.id_colaborador,
          id_indicador: this.campanha.id_indicador,
          id_tipo_meta: 10,
          meta_campanha: this.metaColaborador,
          valor_bonus: this.bonusColaborador,
          meta_gmr: item.meta_gmr,
          id_produto: this.campanha.id_produto,
          id_tipo_indice: this.tiposIndices.selected
        };
        serviceParametro.postMetaModeloColaborador(form);
        this.$notify({
          type: "success",
          text: `${item.colaborador} adicionado na campanha`
        });
      });
      Promise.all(promises)
        .then(() => this.refreshAddAll())
        .catch(error => {
          console.error(error);
          this.$notify({
            type: "error",
            text: "Erro ao adicionar colaborador"
          });
        });
    },

    async addColaboradoesParcitipantes(item) {
      try {
        const form = {
          id_empresa: item.id_empresa,
          id_campanha: this.$route.params.idCampanha,
          id_funcao: item.id_funcao,
          id_colaborador: item.id_colaborador,
          id_indicador: this.campanha.id_indicador,
          id_tipo_meta: 10,
          meta_campanha: this.metaColaborador,
          valor_bonus: this.bonusColaborador,
          meta_gmr: item.meta_gmr,
          id_produto: this.campanha.id_produto,
          id_tipo_indice: this.tiposIndices.selected
        };
        this.metaColaborador = 0;
        this.bonusColaborador = 0;
        await serviceParametro.postMetaModeloColaborador(form);
        this.$notify({
          type: "success",
          text: `${item.colaborador} adicionado na campanha`
        });

        this.refreshAdd(item);
      } catch (error) {
        this.$notify({
          type: "error",
          text: ` Erro ao adicionar  ${item.colaborador}`
        });
      }
    },

    async removerTodosColaboradores() {
      await Promise.all(
        this.colaboradoresParticipantes.map(item => {
          serviceParametro.deleteMetaModeloColaborador(item.id_meta_modelo);
          this.$notify({
            type: "warning",
            text: `${item.colaborador} removido da campanha`
          });
          this.refreshRemove();
        })
      ).catch(erro => {
        console.log(erro);
        this.$notify({
          type: "error",
          text: `Erro ao remover equipe`
        });
      });
    },

    async removeColaboradoresParticipantes(item) {
      try {
        await serviceParametro.deleteMetaModeloColaborador(item.id_meta_modelo);

        this.$notify({
          type: "warning",
          text: `${item.colaborador} removida da campanha`
        });
        this.refreshRemove();
      } catch (error) {
        this.$notify({
          type: "error",
          text: `Error ao remove ${item.colaborador} `
        });
      }
    },

    moveAdded(evt) {
      let colaborador = {};
      const { removed } = evt;

      if (removed) {
        colaborador = removed.element;
        this.addColaboradoesParcitipantes(colaborador);
      }
    },

    moveRemove(evt) {
      let colaborador = {};
      const { removed } = evt;

      if (removed) {
        colaborador = removed.element;
        this.removeColaboradoresParticipantes(colaborador);
      }
    },

    close() {
      this.$emit("close");
    },

    refreshAdd(item) {
      this.colaboradoresNaoParticipantes.splice(
        this.colaboradoresNaoParticipantes.indexOf(item),
        1
      );
      this.$emit("atualizaColaboradoresParticipantes");
    },

    refreshAddAll() {
      this.$emit("atualizaColaboradoresParticipantes");
      this.$emit("atualizaColaboradoresNaoParticipantes");
    },

    refreshRemove() {
      this.$emit("atualizaColaboradoresParticipantes");
      this.$emit("atualizaColaboradoresNaoParticipantes");
    }
  },

  mounted() {
    this.fetchTipoIndice();
  }
};
</script>

<style scoped>
.v-list-item__content {
  padding-inline-start: 15px;
  border: 1px solid hsla(0, 0%, 31%, 0.199);
  border-radius: 10px;
  margin-bottom: 4px;
}

.draggable-area {
  min-height: 280px;
  padding: 8px;
}

.draggable-area.include-persons {
  border: 2px dashed hsla(128, 100%, 34%, 0.39);
  height: 30vh;
  overflow: auto;
}

.draggable-area.include-persons::-webkit-scrollbar-track {
  background-color: #adf3af;
}
.draggable-area.include-persons::-webkit-scrollbar {
  width: 6px;
}
.draggable-area.include-persons::-webkit-scrollbar-thumb {
  background: #2a772c;
}

.draggable-area.exclude-persons {
  border: 2px dashed hsla(347, 100%, 34%, 0.39);
  height: 65vh;
  overflow: auto;
}

.draggable-area.exclude-persons::-webkit-scrollbar-track {
  background-color: #e7b8b8;
}
.draggable-area.exclude-persons::-webkit-scrollbar {
  width: 6px;
}
.draggable-area.exclude-persons::-webkit-scrollbar-thumb {
  background: #9b3232;
}

.v-list-item__content .list {
  display: flex !important;
  justify-content: space-between;
}

.list-colaborador-items {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-participantes-items {
  display: flex !important;
}

.list-equipe {
  display: flex !important;
  flex-direction: column;
}
</style>
