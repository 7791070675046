<template>
  <v-dialog v-model="dialog" persistent max-width="900px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          Vincular Chassi
        </span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form ref="form">
          <v-row>
            <v-col>
              <h4 class="mb-2">
                <v-icon class="mr-1" color="red">mdi-close</v-icon>Chassi Fora
                da Campanha
              </h4>
              <v-text-field
                prepend-icon="mdi-magnify"
                placeholder="Chassi Completo"
                v-model="searchChassi"
                :loading="searchLoading"
                clearable
              />

              <draggable
                class="draggable-area exclude-companies"
                :list="chassiNaoParticipantes"
                group="modelos"
                @change="moveAdded"
              >
                <div
                  class="v-list-item__content list"
                  v-for="chassi in chassiNaoParticipantes"
                  :key="chassi.chassi_completo"
                >
                  <div class="list-equipe-items">
                    <div class="list-equipe">
                      <v-row class="ml-0 mb-2">
                        <strong class="mr-1">{{
                          `${chassi.chassi_completo} -`
                        }}</strong>
                        {{ chassi.descricao_modelo }}
                      </v-row>
                      <div class="veiculo-details">
                        <v-row class="ml-0">
                          <v-col cols="4" xs="12" class="d-flex pa-1">
                            <p class="mr-1">Novo:</p>
                            {{ chassi.veiculo_novo.toLowerCase() }}
                          </v-col>
                          <v-col cols="4" xs="12" class="d-flex pa-1">
                            <p class="mr-1">Dias pátio:</p>
                            {{ chassi.dia_patio }}
                          </v-col>
                          <v-col class="d-flex pa-1" cols="4" xs="12">
                            <p class="mr-1">Segmento:</p>
                            <BaseSegmento :segmento="chassi.cod_band" />
                          </v-col>
                        </v-row>
                        <v-row class="ml-0 ">
                          <v-col cols="4" class="d-flex pa-1">
                            <p class="mr-1">Cor:</p>
                            {{ chassi.cor.toLowerCase() }}
                          </v-col>
                          <v-col cols="4" class="d-flex pa-1">
                            <p class="mr-1">Empresa:</p>
                            {{ chassi.apelido }}
                          </v-col>
                          <v-col class="d-flex pa-1 pb-0" cols="4">
                            <p class="mr-1">Reservado:</p>
                            {{ chassi.reservado }}
                          </v-col>
                        </v-row>
                      </div>
                    </div>

                    <div class="mr-1">
                      <v-btn icon @click="addChassisParcitipantes(chassi)">
                        <v-icon color="green">mdi-arrow-right-bold</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </draggable>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col>
              <h4 class="mb-2">
                <v-icon class="mr-1" color="green">mdi-check</v-icon>Chassi
                Participantes da Campanha
              </h4>
              <v-btn
                class="mb-3"
                color="error"
                @click="() => {}"
                :disabled="chassiParticipantes.length == 0"
              >
                Remover todos
              </v-btn>
              <draggable
                class="draggable-area include-companies"
                :list="chassiParticipantes"
                group="equipes"
                @change="moveRemove"
              >
                <div
                  class="v-list-item__content"
                  v-for="chassi in chassiParticipantes"
                  :key="chassi.id_produto"
                >
                  <div class="list-equipe-participantes-items">
                    <div>
                      <v-btn
                        class="mr-2"
                        icon
                        @click="removeChassisParticipantes(chassi)"
                      >
                        <v-icon color="red">mdi-arrow-left-bold</v-icon>
                      </v-btn>
                    </div>

                    <div class="list-equipe mr-4 w-100">
                      <v-row class="ml-0 mb-2">
                        <strong class="mr-1">{{
                          `${chassi.chassi_completo} -`
                        }}</strong>

                        {{ chassi.descricao_modelo }}
                      </v-row>
                      <div class="veiculo-details">
                        <v-row class="ml-0">
                          <v-col cols="7" xs="12" class="d-flex pa-1">
                            <p class="mr-1">Bônus:</p>
                            {{ formatValorBonus(chassi.valor_bonus) }}
                          </v-col>

                          <v-col class="d-flex pa-1" cols="4" xs="12">
                            <p class="mr-1">Segmento:</p>
                            <BaseSegmento :segmento="chassi.cod_band" />
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>
              <v-col cols="12" class="mt-2">
                <v-text-field
                  prefix="R$"
                  label="Bônus do Colaborador"
                  v-model.number="bonusColaborador"
                  type="number"
                  :rules="rules.limit"
                >
                </v-text-field>
                <div class="d-flex">
                  <v-row>
                    <v-col class="pa-1 pt-2">Min: {{ 1 }}</v-col>
                    <v-col class="pa-1 pt-2"
                      >Máx: {{ bonusMaximo || "Não definido" }}</v-col
                    >
                  </v-row>
                </div>
              </v-col>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseSegmento from "@/components/shared/BaseSegmento";
import serviceCampanha from "@/services/campanha";
import draggable from "vuedraggable";
import formatValorBonus from "../../../../../utils/formatValorBonus";

export default {
  name: "DraggableChassiCampanha",

  components: {
    draggable,
    BaseSegmento
  },

  data() {
    return {
      rules: {
        limit: [
          v =>
            (parseFloat(v) <= parseFloat(this.bonusMaximo) &&
              parseFloat(v) >= parseFloat(1)) ||
            `Valor do bônus está fora do permitido`
        ]
      },
      formatValorBonus: formatValorBonus,
      searchChassiTimeout: null,
      searchChassi: "",
      bonusColaborador: 0,
      optionsVuetifyMoneyBonus: {
        locale: "en",
        prefix: "R$",
        length: 8,
        precision: 2
      }
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    campanha: {
      type: Object,
      default: () => ({})
    },

    chassiNaoParticipantes: {
      type: Array,
      default: () => []
    },

    chassiParticipantes: {
      type: Array,
      default: () => []
    },
    searchLoading: {
      type: Boolean
    },
    bonusMaximo: {
      type: Number,
      default: () => 0
    }
  },

  watch: {
    searchChassi(newVal) {
      if (this.searchChassiTimeout) {
        clearTimeout(this.searchChassiTimeout);
      }

      this.searchChassiTimeout = setTimeout(() => {
        this.$emit("filterChassi", { chassi_completo: newVal });
      }, 1000);
    }
  },

  methods: {
    async addChassisParcitipantes(item) {
      const validateStatus = this.$refs.form.validate();
      if (!validateStatus) {
        this.$notify({
          type: "warning",
          text: `Verifique os dados do formulário`
        });
        return;
      }
      try {
        const form = {
          id_campanha: this.$route.params.idCampanha,
          chassi_completo: item.chassi_completo,
          id_funcao: this.campanha.id_funcao,
          id_indicador: this.campanha.id_indicador,
          valor_bonus: this.bonusColaborador,
          chassi_resumido: item.chassi_resumido
        };
        await serviceCampanha.postCampanhaChassi(form);
        this.$notify({
          type: "success",
          text: `Chassi ${item.descricao_modelo} adicionado na campanha`
        });
        this.bonusColaborador = "0";
        this.refreshAdd(item, form);
      } catch (error) {
        this.$notify({
          type: "error",
          text: `Erro ao adicionar Chassi`
        });
      }
    },

    async removeChassisParticipantes(item) {
      try {
        const form = {
          id_campanha: this.$route.params.idCampanha,
          chassi_completo: item.chassi_completo,
          id_funcao: this.campanha.id_funcao,
          id_indicador: this.campanha.id_indicador,
          valor_bonus: this.bonusColaborador,
          chassi_resumido: item.chassi_resumido
        };

        await serviceCampanha.deleteCampanhaChassi(item.id_campanha_chassi);
        this.$notify({
          type: "warning",
          text: `Chassi ${item.descricao_modelo} removido da campanha`
        });
        this.refreshRemove(form);
      } catch (error) {
        this.$notify({
          type: "error",
          text: `Error ao remover Chassi`
        });
      }
    },

    moveAdded(evt) {
      let modelo = {};
      const { removed } = evt;

      if (removed) {
        modelo = removed.element;
        this.addChassisParcitipantes(modelo);
      }
    },

    moveRemove(evt) {
      let modelo = {};
      const { removed } = evt;

      if (removed) {
        modelo = removed.element;
        this.removeChassisParticipantes(modelo);
      }
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    },

    refreshAdd(item, form) {
      this.chassiNaoParticipantes.splice(
        this.chassiNaoParticipantes.indexOf(item),
        1
      );
      this.$emit("atualizaChassisParticipantes", form);
    },

    refreshRemove(form) {
      this.$emit("atualizaChassisParticipantes", form);
      this.$emit("atualizaChassisNaoParticipantes", form);
    }
  }
};
</script>

<style>
.veiculo-details {
  color: black;
  font-weight: 600;
  font-size: 0.8rem;
}
.draggable-area .v-list-item__content {
  padding-inline-start: 15px;
  border: 1px solid hsla(0, 0%, 31%, 0.5);
  border-radius: 10px;
  margin-bottom: 4px;
}

.draggable-area {
  min-height: 300px;
  padding: 8px;
}

.draggable-area.include-companies {
  border: 2px dashed hsla(128, 100%, 34%, 0.39);
  height: 50vh;
  overflow: auto;
}

.draggable-area.include-companies::-webkit-scrollbar-track {
  background-color: #adf3af;
}
.draggable-area.include-companies::-webkit-scrollbar {
  width: 6px;
}
.draggable-area.include-companies::-webkit-scrollbar-thumb {
  background: #2a772c;
}

.draggable-area.exclude-companies {
  border: 2px dashed hsla(347, 100%, 34%, 0.39);
  height: 60vh;
  overflow: auto;
}

.draggable-area.exclude-companies::-webkit-scrollbar-track {
  background-color: #e7b8b8;
}
.draggable-area.exclude-companies::-webkit-scrollbar {
  width: 6px;
}
.draggable-area.exclude-companies::-webkit-scrollbar-thumb {
  background: #9b3232;
}

.v-list-item__content .list {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-items {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-participantes-items {
  display: flex !important;
}

.list-equipe {
  display: flex !important;
  flex-direction: column;
}
</style>
