<template>
  <div>
    <div class="d-flex mb-6">
      <p class="title ma-0">Cadastro de metas da campanha</p>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            :href="'https://www.youtube.com/watch?v=JfpyW1pEaa4'"
            target="_blank"
          >
            <v-icon class="ml-2">mdi-help-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>Ajuda</span>
      </v-tooltip>
    </div>
    <template v-if="onEdite">
      <p class="subtitle-1 mb-0">
        Para cada público alvo cadastre seus parâmetros de meta
      </p>
      <p class="caption">
        Os parâmetros serão exbidos com base no público alvo escolhido
        anteriormente
      </p>
    </template>
    <v-divider></v-divider>

    <!--Tabela Funções selecionadas para a campanha-->
    <TableFuncoesPublicoAlvo
      :onEdite="onEdite"
      :funcoes="getFuncoesPublicoAlvoSelecionado"
      @onCadastrarMeta="addItem($event)"
      class="mb-5"
    />

    <v-card>
      <!--Tabela Metas Cadastradas-->
      <v-data-table
        :headers="headers"
        :items="getMetasCadastradas"
        no-data-text="Nenhuma meta cadastrada"
        sort-by="cod_funcao"
        class="elevation-1 mb-3"
        :loading="tableMetasCadastradasloading"
        loading-text="Loading..."
      >
        <template v-slot:top>
          <v-card-title> Metas Cadastradas</v-card-title>
        </template>
        <!-- Descrição -->
        <!-- <template v-slot:[`item.id_band`]="{ item }">
          <BaseSegmento :segmento="item.id_band" />
        </template> -->

        <template v-slot:[`item.funcao`]="{ item }">
          <LineTable
            class="mt-2 mb-2"
            :code="item.cod_funcao"
            :description="item.funcao"
            :segmento="item.id_band"
          />
        </template>
        <!-- Descrição -->

        <template v-slot:[`item.indicador`]="{ item }">
          <LineTable :code="item.id_indicador" :description="item.indicador" />
        </template>

        <template v-slot:[`item.perc_cresc`]="{ item }">
          <span v-if="item.perc_cresc">
            <v-icon color="green">mdi-finance</v-icon>
            {{ item.perc_cresc }}%
          </span>
          <span v-else>
            <v-icon color="error">mdi-close</v-icon>
          </span>
        </template>

        <!-- Valor Bonus -->
        <template v-slot:[`item.valor_bonus`]="{ item }">
          <span v-if="item.valor_bonus">
            {{ valorBonus(item) }}
          </span>
          <span v-else>
            <v-icon color="error">mdi-close</v-icon>
          </span>
        </template>

        <!-- Meta -->
        <template v-slot:[`item.valor_meta`]="{ item }">
          <span v-if="item.valor_meta">
            {{ valorMeta(item) }}
          </span>
          <span v-else>
            <v-icon color="error">mdi-close</v-icon>
          </span>
        </template>

        <!-- Base GMR -->
        <template v-slot:[`item.atrela_gmr`]="{ item }">
          <v-icon v-if="item.atrela_gmr == 'S'" color="success"
            >mdi-check</v-icon
          >
          <v-icon v-else-if="item.atrela_gmr == 'N'" color="error"
            >mdi-close</v-icon
          >
        </template>

        <!-- Percentual GMR -->
        <template v-slot:[`item.pec_gmr`]="{ item }">
          {{ item.pec_gmr }}%
        </template>

        <!-- Percentual GMR Dobro -->
        <template v-slot:[`item.pec_gmr_dobro`]="{ item }">
          {{ item.pec_gmr_dobro }}%
        </template>

        <!-- Prioridade do indicador -->
        <template v-slot:[`item.maior_menor`]="{ item }">
          <span v-if="item.maior_menor === '>'">
            <v-icon class="mr-1" small color="green">mdi-arrow-up </v-icon>
            Maior
          </span>
          <span v-if="item.maior_menor === '<'">
            <v-icon class="mr-1" small color="blue">mdi-arrow-down </v-icon>
            Menor
          </span>
        </template>

        <!-- Ações -->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex align-center">
            <v-btn
              small
              icon
              class="ml-1"
              @click="fetchColaboradores(item)"
              color="primary"
              dark
            >
              <v-icon>mdi-account-search</v-icon>
            </v-btn>

            <v-btn
              v-if="onEdite"
              small
              icon
              class="ml-1"
              @click="editItem(item)"
              color="orange"
              dark
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn
              v-if="onEdite"
              small
              icon
              class="ml-1"
              @click="removeItem(item)"
              color="error"
              dark
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <!--Tabela Metas Progressivas-->
    <tabela-meta-progressiva
      v-if="redenrizarMetasProgressivas()"
      :onEdite="onEdite"
    />

    <!--Tabela Metas Equipes-->
    <v-card>
      <TableMetaEquipes v-if="redenrizarMetasEquipes()" :onEdite="onEdite" />
    </v-card>

    <!--Tabela Metas Semanal-->
    <v-card>
      <TableMetaSemanal
        v-if="redenrizarMetaSemanal()"
        :items="getMetasCadastradas"
      />
    </v-card>

    <!-- Tabela Metas Modelo -->
    <v-card>
      <TabelaMetaModelo v-if="redenrizarMetasModelos()" />
    </v-card>

    <v-card>
      <TabelaMetaChassi v-if="renderizarMetasChassi()" />
    </v-card>
    <!-- Modal form cadastro das Funções da Campanha -->
    <ModalCadastroIndicadores
      ref="modalCadastroIndicadoresRef"
      :funcao="cadastroIndicador.funcao"
      @campanhaFuncaoCadastrada="campanhaFuncaoCadastrada()"
      @close="closeModalCasdastroIndicador()"
    />

    <ModalColaboradoresPublicoAlvo
      :items="colaboradoresEmpresa.data"
      :show="colaboradoresEmpresa.show"
      @close="colaboradoresEmpresa.data = []"
    />
  </div>
</template>

<script>
import LineTable from "@/components/shared/format/LineTable.vue";
import currencyMixin from "@/mixins/currencyMixin";
import campanhaService from "@/services/campanha";
import colaboradorService from "@/services/colaborador";
import { mapGetters } from "vuex";
import initMixin from "../../initMixin";
import ModalCadastroIndicadores from "./ModalCadastroIndicadores";
import TabelaMetaModelo from "./modelo/Tabela";
import TabelaMetaChassi from "./modelo/TabelaMetaChassi.vue";
import TabelaMetaProgressiva from "./progressivo/TabelaMetaProgressiva.vue";
import TableFuncoesPublicoAlvo from "./TableFuncoesPublicoAlvo";
import TableMetaEquipes from "./TableMetaEquipes";
import TableMetaSemanal from "./TableMetaSemanal";
export default {
  // Verificar se realmente é função do público ou
  // se é meta por função
  // Criar o formulário para todos os públicos
  // Amarrar o formulário por etapas
  name: "SectionPublicoMeta",
  props: {
    onEdite: {
      type: Boolean,
      default: true
    }
  },
  mixins: [initMixin, currencyMixin],
  components: {
    // BaseSegmento: () => import("@/components/shared/BaseSegmento"),
    ModalColaboradoresPublicoAlvo: () =>
      import("./ModalColaboradoresPublicoAlvo"),
    ModalCadastroIndicadores,
    TableFuncoesPublicoAlvo,
    TabelaMetaProgressiva,
    TableMetaEquipes,
    TableMetaSemanal,
    TabelaMetaChassi,
    TabelaMetaModelo,
    LineTable
  },

  data() {
    return {
      cadastroIndicador: {
        funcao: {}
      },
      showb: true,
      funcoesCadastradas: [],
      tableMetasCadastradasloading: false,
      editedIndex: -1,
      colaboradoresEmpresa: {
        data: [],
        show: false
      }
    };
  },

  mounted() {
    this.getFuncoesCampanha();
  },

  computed: {
    ...mapGetters({
      getFuncoesPublicoAlvoSelecionado: "campanha/getPublicoAlvoSelecionado",
      getCurrentCampanha: "campanha/getCurrentCampanha",
      getMetasCadastradas: "campanha/getMetasCadastradas",
      getEmpresasParticipantes: "campanha/getEmpresasParticipantes",
      getStatusMetas: "campanha/getStatusMetas",
      getFuncaoCampanhaProgressiva: "campanha/getFuncaoCampanhaProgressiva",
      getStatusMetasPrograssivas: "campanha/getStatusMetasPrograssivas"
    }),

    headers() {
      let headers = [
        // {
        //   text: "Segmento",
        //   value: "id_band",
        //   sortable: false
        // },
        { text: "Função", value: "funcao" },
        { text: "Indicador", value: "indicador" },
        { text: "Meta", value: "tipo_meta" },
        { text: "B. Cálculo", value: "tipo_calculo", sortable: false },
        { text: "Pagamento", value: "tipo_pagamento", align: "left" },
        {
          text: "Meta Valor",
          value: "valor_meta",
          align: "center",
          sortable: false
        },
        {
          text: "GMR",
          value: "atrela_gmr",
          align: "center",
          sortable: false
        },
        { text: "GMR %", value: "pec_gmr", align: "right", sortable: false },
        {
          text: "Dobro GMR %",
          value: "pec_gmr_dobro",
          align: "right",
          sortable: false
        },
        // {
        //   text: "% Cresc.",
        //   value: "perc_cresc",
        //   align: "center",
        //   sortable: false
        // },
        {
          text: "Bônus",
          value: "valor_bonus",
          align: "right",
          sortable: false
        },
        {
          text: "Prioridade",
          value: "maior_menor",
          align: "right",
          sortable: false
        },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "center"
        }
      ];

      return headers;
    },
    validBtnNextStep() {
      return !this.disabledBtnPublicoMeta(
        !(this.getStatusMetasPrograssivas && this.getStatusMetas)
      );
    }
  },

  methods: {
    nextResumo() {
      this.$router.push("resumo");
    },

    disabledBtnPublicoMeta(value) {
      if (value) {
        this.menssageButton = "Cadastre todas as metas para continuar";
        return value;
      }
      this.menssageButton = "Continue";
    },
    async fetchColaboradores(item) {
      try {
        const filter = {
          ativo: "S",
          id_funcao: item.id_funcao,
          id_empresa: "",
          per_page: -1
        };

        filter.id_empresa = this.getEmpresasParticipantes.map(
          empresa => empresa.id_empresa
        );

        let response = await colaboradorService.getColaboradoresEmpresa(filter);

        if (response.data.data.length <= 0) {
          this.$notify({
            type: "error",
            text: "A função selecionada não possui colaboradores"
          });
        }
        this.colaboradoresEmpresa.data = response.data.data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao buscar colaboradores"
        });
      }
    },

    async getFuncoesCampanha() {
      try {
        this.tableMetasCadastradasloading = true;
        await this.initActionFeatMetasCadastradas();
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Houve um erro"
        });
        ////(error);
      } finally {
        this.tableMetasCadastradasloading = false;
      }
    },

    valorMeta(item) {
      if (item.tipo_meta == "Definir Equipe") return "Individual";
      if (
        item.tipo_meta == "Progressiva" ||
        item.tipo_meta == "Progressiva Emp"
      )
        return "Por Faixa";
      return item.valor_meta;
    },

    valorBonus(item) {
      if (item.tipo_meta == "Definir Equipe") return "Individual";
      if (
        item.tipo_meta == "Progressiva" ||
        item.tipo_meta == "Progressiva Emp"
      )
        return "Por Faixa";
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(item.valor_bonus);
    },

    campanhaFuncaoCadastrada() {
      this.getFuncoesCampanha();
      this.closeModalCasdastroIndicador();
    },

    closeModalCasdastroIndicador() {
      this.$refs.modalCadastroIndicadoresRef.show = false;
      this.cadastroIndicador.funcao = {};
      this.$refs.modalCadastroIndicadoresRef.$refs.formCadastroMeta.reset();
      this.$refs.modalCadastroIndicadoresRef.resetValidation();
    },

    addItem(item) {
      this.$refs.modalCadastroIndicadoresRef.show = true;
      this.cadastroIndicador.funcao = {
        id_funcao: item.id_funcao,
        descricao: item.descricao,
        id_band: item.id_band
      };
      this.$refs.modalCadastroIndicadoresRef.labelBtnSend = "Salvar";
      this.$refs.modalCadastroIndicadoresRef.methodSend = "postCampanhaFuncao";
    },

    editItem(item) {
      this.$refs.modalCadastroIndicadoresRef.show = true;
      this.cadastroIndicador.funcao = item;
      this.$refs.modalCadastroIndicadoresRef.labelBtnSend = "Salvar Edição";
      this.$refs.modalCadastroIndicadoresRef.methodSend = "putCampanhaFuncao";
      // this.editedIndex = this.desserts.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      // this.dialog = true;
    },

    async removeItem(item) {
      try {
        await this.$swal.confirm("Deseja deletar?");
        await campanhaService.deleteCampanhaFuncao(item.id_campanha_funcao);
        this.initActionFeatMetasCadastradas();
        this.$notify({
          type: "warning",
          text: "Meta removida do publico alvo!"
        });
      } catch (error) {
        if (error != undefined) {
          this.$notify({
            type: "error",
            text: "Se a meta for progressiva verifique se a  faixa cadastrada"
          });
        }
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },

    redenrizarMetasProgressivas() {
      const metasProgressivas = this.getFuncaoCampanhaProgressiva.length;

      return metasProgressivas ? true : false;
    },

    redenrizarMetasEquipes() {
      const metasEquipes = this.getMetasCadastradas?.filter(
        item => item.id_tipo_meta == 6 || item.id_tipo_meta == 21
      );

      const valueArray = metasEquipes?.length;
      return valueArray ? true : false;
    },

    redenrizarMetaSemanal() {
      const metasModelos = this.getMetasCadastradas?.some(
        item => item.id_tipo_dado == "C"
      );
      return metasModelos;
    },

    redenrizarMetasModelos() {
      const metasModelos = this.getMetasCadastradas?.some(
        item => item.id_tipo_meta == 10
      );
      return metasModelos;
    },

    renderizarMetasChassi() {
      const metasModelos = this.getMetasCadastradas?.some(
        item => item.id_tipo_meta == 201
      );
      return metasModelos;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.initActionFuncaoPublicoAlvo();
    this.initActionFeatMetasCadastradas();
  }
};
</script>
<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
</style>
