<template>
  <v-dialog v-model="dialog" max-width="60%" scrollable persistent>
    <v-card>
      <v-card-title>
        <span class="headline">
          Vincular Colaborador -
          <strong>{{ dadosCampanha.nome_equipe }}</strong>
        </span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row>
          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="red">mdi-close</v-icon>Colaboradores
              Fora da Campanha
            </h4>
            <v-btn
              class="mb-3"
              color="success"
              @click="addTodosColaboradores()"
              :disabled="
                form.tipo_indice == 1
                  ? disabledButtonUN()
                  : disabledButtonPercent()
              "
            >
              Adicionar todos
            </v-btn>
            <draggable
              class="draggable-area exclude-persons"
              :list="colaboradoresNaoParticipantes"
              group="colaboradores"
              @change="moveAdded()"
            >
              <div
                class="v-list-item__content list"
                v-for="colaborador in colaboradoresNaoParticipantes"
                :key="colaborador.id_colaborador_equipe"
              >
                <div class="list-colaborador-items">
                  <div class="list-equipe">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${colaborador.cod_funcao} -`
                      }}</strong>
                      {{ colaborador.nome || colaborador.colaborador }}
                    </v-row>
                    <BaseSegmento :segmento="colaborador.id_band" />
                  </div>
                  <div class="mr-4">
                    <v-btn
                      icon
                      @click="addColaboradoesParcitipantes(colaborador)"
                      :disabled="
                        form.tipo_indice == 1
                          ? disabledArrowUN()
                          : disabledArrowPercent()
                      "
                    >
                      <v-icon color="green">mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="green">mdi-check</v-icon>Colaboradores
              Participantes da Campanha
            </h4>
            <v-btn
              class="mb-3"
              color="error"
              @click="removerTodosColaboradores()"
              :disabled="colaboradoresParticipantes.length == 0"
            >
              Remover todos
            </v-btn>
            <draggable
              class="draggable-area include-persons"
              :list="colaboradoresParticipantes"
              group="colaboradores"
              @change="moveRemove()"
            >
              <div
                class="v-list-item__content"
                v-for="colaborador in colaboradoresParticipantes"
                :key="colaborador.id_colaborador"
              >
                <div class="list-equipe-participantes-items">
                  <div>
                    <v-btn
                      class="mr-4"
                      icon
                      @click="removeColaboradoresParticipantes(colaborador)"
                    >
                      <v-icon color="red">mdi-arrow-left-bold</v-icon>
                    </v-btn>
                  </div>
                  <div class="list-equipe mr-4">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${colaborador.cod_funcao} -`
                      }}</strong>

                      {{ colaborador.nome }} - Meta:
                      {{ colaborador.meta_campanha }} - Bônus:
                      {{ colaborador.valor_bonus }}
                    </v-row>
                    <BaseSegmento :segmento="colaborador.id_band" />
                  </div>
                </div>
              </div>
            </draggable>
            <v-divider class="mt-5 mb-5"></v-divider>

            <v-row dense align="center">
              <v-col cols="12">
                <BaseFilter
                  clearable
                  single-line
                  label="Tipo de Meta"
                  item-text="descricao"
                  item-value="id_tipo_indice"
                  service="campanhaService.tipoIndice"
                  v-model="form.tipo_indice"
                  @change="clearData()"
                />
              </v-col>
              <v-col v-if="form.tipo_indice != '2'" cols="6" class="mt-2">
                <vuetify-money
                  v-model.number="metaColaborador"
                  label="Meta do Colaborador"
                  valueWhenIsEmpty="0"
                  :rules="tipoDado != 'X' ? rules : rulesTipoDado"
                  :options="optionsVuetifyMoney"
                />
              </v-col>
              <v-col v-else cols="6" class="mt-2">
                <BaseFilter
                  prepend-inner-icon="mdi-percent"
                  clearable
                  single-line
                  label="Índice de Crescimento"
                  item-text="perc_cresc"
                  item-value="perc_cresc"
                  service="campanhaService.crescimento"
                  v-model="form.perc_cresc"
                />
              </v-col>
              <v-col cols="6" class="mt-2">
                <vuetify-money
                  v-model.number="bonusColaborador"
                  label="Bônus do Colaborador"
                  valueWhenIsEmpty="0"
                  :options="optionsVuetifyMoneyBonus"
                />
              </v-col>
              <v-col cols="12">
                <v-alert
                  v-if="
                    form.tipo_indice == 1
                      ? disabledButtonUN()
                      : disabledButtonPercent()
                  "
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                  Digite uma meta e um bonus para efetuar o vínculo de
                  colaboradores
                </v-alert>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseFilter from "@/components/shared/BaseSelectService";
import serviceCampanha from "@/services/campanha";
import campanha from "@/services/http/campanhaService";
import comissao from "@/services/http/comissaoService";
import draggable from "vuedraggable";

export default {
  name: "DraggableColaboradorCampanha",

  components: {
    draggable,
    BaseSegmento: () => import("@/components/shared/BaseSegmento.vue"),

    BaseFilter
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    dadosCampanha: {
      type: Object
    },

    colaboradoresNaoParticipantes: {
      type: Array
    },

    colaboradoresParticipantes: {
      type: Array
    }
  },

  data() {
    return {
      tipoDado: "",
      minMeta: 0,
      metaColaborador: 0,
      bonusColaborador: 0,
      form: {
        tipo_indice: 1
      },

      optionsVuetifyMoneyBonus: {
        locale: "pt-BR",
        prefix: "R$",
        length: 8,
        precision: 2
      },
      rules: [
        value =>
          value >= parseFloat(this.minMeta) ||
          `O valor da meta deve ser maior que ${this.minMeta - 1}`
      ],
      rulesTipoDado: [
        value => value > 0 || `O valor da meta deve ser maior que 0`
      ]
    };
  },

  watch: {
    colaboradoresNaoParticipantes(value) {
      return value;
    },

    colaboradoresParticipantes(value) {
      return value;
    },

    dadosCampanha(value) {
      this.fetchMetas();
      return value;
    }
  },

  computed: {
    seeDraggableAddColaboradores() {
      return this.metaColaborador >= 1;
    },

    optionsVuetifyMoney() {
      return {
        locale: "pt-BR",
        prefix: this.form.tipo_indice == 1 ? "UN" : "R$",
        length: this.form.tipo_indice == 1 ? 3 : 8,
        precision: this.form.tipo_indice == 1 ? 0 : 2
      };
    }
  },

  methods: {
    clearData() {
      this.metaColaborador = 0;
      this.bonusColaborador = 0;
      this.form.perc_cresc = "";
    },

    disabledButtonUN() {
      if (
        this.metaColaborador <= 0 ||
        this.bonusColaborador <= 0 ||
        this.colaboradoresNaoParticipantes.length == 0 ||
        this.metaColaborador < parseFloat(this.minMeta)
      ) {
        return true;
      }
      return false;
    },

    disabledButtonPercent() {
      if (
        this.form.perc_cresc <= 0 ||
        this.bonusColaborador <= 0 ||
        this.colaboradoresNaoParticipantes.length == 0
      ) {
        return true;
      }
      return false;
    },

    disabledArrowUN() {
      if (
        this.metaColaborador <= 0 ||
        this.bonusColaborador <= 0 ||
        this.colaboradoresNaoParticipantes.length == 0 ||
        this.metaColaborador < parseFloat(this.minMeta)
      ) {
        return true;
      }
      return false;
    },

    disabledArrowPercent() {
      if (
        this.form.perc_cresc <= 0 ||
        this.bonusColaborador <= 0 ||
        this.colaboradoresNaoParticipantes.length == 0
      ) {
        return true;
      }
      return false;
    },

    valorMeta() {
      if (this.form.tipo_indice != 2) {
        return parseFloat(this.metaColaborador);
      }
      return parseFloat(this.form.perc_cresc);
    },

    async fetchMetas() {
      try {
        const { data } = await comissao()
          .metaFuncao()
          .show({
            cod_funcao: this.dadosCampanha.cod_funcao,
            id_tipo_meta: 2,
            id_indicador: this.dadosCampanha.id_indicador
          });
        this.minMeta = data.data[0].meta_valor;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchCampanha(id) {
      let { data: data } = await campanha().show({
        id_campanha: id
      });
      this.tipoDado = data.data[0].id_tipo_dado;
    },

    async addTodosColaboradores() {
      try {
        let allPost = this.colaboradoresNaoParticipantes.map(item => {
          const form = {
            id_campanha: this.$route.params.idCampanha,
            id_empresa: item.id_empresa,
            id_colaborador: item.id_colaborador,
            id_funcao: item.id_funcao,
            id_indicador: this.dadosCampanha.id_indicador,
            id_campanha_equipe: this.dadosCampanha.id_campanha_equipe,
            id_equipe: item.id_equipe,
            valor_bonus: this.bonusColaborador,
            meta_campanha: this.valorMeta(),
            id_tipo_indice: this.form.tipo_indice
          };
          return serviceCampanha.postColaboradoresCampanha(form);
        });
        await Promise.all(allPost);
        this.$notify({
          type: "success",
          text: `Colaboradores adicionados na campanha`
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: `Erro ao adicionar colaboradores`
        });
      } finally {
        this.$emit("change:fecthEquipes", this.dadosCampanha);
      }
    },

    async addColaboradoesParcitipantes(item) {
      try {
        const form = {
          id_campanha: this.$route.params.idCampanha,
          id_empresa: item.id_empresa,
          id_colaborador: item.id_colaborador,
          id_funcao: item.id_funcao,
          id_indicador: this.dadosCampanha.id_indicador,
          id_campanha_equipe: this.dadosCampanha.id_campanha_equipe,
          id_equipe: item.id_equipe,
          valor_bonus: this.bonusColaborador,
          meta_campanha: this.valorMeta(),
          id_tipo_indice: this.form.tipo_indice
        };
        await serviceCampanha.postColaboradoresCampanha(form);
        this.metaColaborador = 0;
        this.bonusColaborador = 0;
        this.$notify({
          type: "success",
          text: `${item.colaborador} adicionado na campanha`
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: ` Erro ao adicionar ${item.colaborador}`
        });
      } finally {
        this.$emit("change:fecthEquipes", this.dadosCampanha);
      }
    },

    async removerTodosColaboradores() {
      try {
        let allDelete = this.colaboradoresParticipantes.map(item => {
          return serviceCampanha.deleteColaboradoresCampanha(
            item.id_campanha_colaborador
          );
        });
        await Promise.all(allDelete);
        this.$notify({
          type: "success",
          text: `Colaboradores adicionados na campanha`
        });
      } catch (error) {
        this.$notify({
          type: "warning",
          text: `Colaboradores removidos da campanha`
        });
      } finally {
        this.$emit("change:fecthEquipes", this.dadosCampanha);
      }
    },

    async removeColaboradoresParticipantes(item) {
      try {
        await serviceCampanha.deleteColaboradoresCampanha(
          item.id_campanha_colaborador
        );

        this.$notify({
          type: "warning",
          text: `${item.nome} removida da campanha`
        });
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "error",
          text: `Error ao remove ${item.nome} `
        });
      } finally {
        this.$emit("change:fecthEquipes", this.dadosCampanha);
      }
    },

    moveAdded(evt) {
      let colaborador = {};
      const { removed } = evt;

      if (removed) {
        colaborador = removed.element;
        this.addColaboradoesParcitipantes(colaborador);
      }
    },

    moveRemove(evt) {
      let colaborador = {};
      const { removed } = evt;

      if (removed) {
        colaborador = removed.element;
        this.removeColaboradoresParticipantes(colaborador);
      }
    },

    close() {
      this.$emit("close");
    }
  },

  mounted() {
    this.fetchMetas();
    this.fetchCampanha(this.$router.history.current.params.idCampanha);
  }
};
</script>

<style scoped>
.v-list-item__content {
  padding-inline-start: 15px;
  border: 1px solid hsla(0, 0%, 31%, 0.199);
  border-radius: 10px;
  margin-bottom: 4px;
}

.draggable-area {
  min-height: 280px;
  padding: 8px;
}

.draggable-area.include-persons {
  border: 2px dashed hsla(128, 100%, 34%, 0.39);
  height: 30vh;
  overflow: auto;
}

.draggable-area.include-persons::-webkit-scrollbar-track {
  background-color: #adf3af;
}
.draggable-area.include-persons::-webkit-scrollbar {
  width: 6px;
}
.draggable-area.include-persons::-webkit-scrollbar-thumb {
  background: #2a772c;
}

.draggable-area.exclude-persons {
  border: 2px dashed hsla(347, 100%, 34%, 0.39);
  height: 65vh;
  overflow: auto;
}

.draggable-area.exclude-persons::-webkit-scrollbar-track {
  background-color: #e7b8b8;
}
.draggable-area.exclude-persons::-webkit-scrollbar {
  width: 6px;
}
.draggable-area.exclude-persons::-webkit-scrollbar-thumb {
  background: #9b3232;
}

.v-list-item__content .list {
  display: flex !important;
  justify-content: space-between;
}

.list-colaborador-items {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-participantes-items {
  display: flex !important;
}

.list-equipe {
  display: flex !important;
  flex-direction: column;
}
</style>
