<template>
  <div class="mt-4">
    <!--Tabela Metas Cadastradas-->
    <v-data-table
      :headers="headers"
      :items="items"
      no-data-text="Nenhuma meta cadastrada"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id_campanha_funcao"
      show-expand
      sort-by="cod_funcao"
      class="elevation-1 mb-3"
      loading-text="Loading..."
    >
      <template v-slot:top>
        <v-card-title> Meta Semanal </v-card-title>
      </template>
      <!-- Descrição -->
      <template v-slot:[`item.funcao`]="{ item }">
        <LineTable
          class="mt-2 mb-2"
          :code="item.cod_funcao"
          :description="item.funcao"
          :segmento="item.id_band"
        />
      </template>

      <template v-slot:[`item.indicador`]="{ item }">
        <LineTable
          class="mt-2 mb-2"
          :code="item.id_indicador"
          :description="item.indicador"
        />
      </template>

      <template v-slot:[`item.perc_cresc`]="{ item }">
        <span v-if="item.perc_cresc">
          <v-icon color="green">mdi-finance</v-icon>
          {{ item.perc_cresc }}%
        </span>
        <span v-else>Não possui</span>
      </template>

      <!-- Prioridade do indicador -->
      <template v-slot:[`item.maior_menor`]="{ item }">
        <span v-if="item.maior_menor === '>'">
          <v-icon class="mr-1" small color="green">mdi-arrow-up </v-icon>
          Maior
        </span>
        <span v-if="item.maior_menor === '<'">
          <v-icon class="mr-1" small color="blue">mdi-arrow-down </v-icon>
          Menor
        </span>
      </template>

      <!-- Lista de Empresas Participantes -->
      <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
        <v-btn
          color="primary"
          small
          v-if="!isExpanded"
          @click="expand(!isExpanded)"
        >
          expandir
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn color="warning" small v-else @click="expand(!isExpanded)">
          ocultar
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <ListaSemanasCampanha :idCampanhaFuncao="item.id_campanha_funcao" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import LineTable from "@/components/shared/format/LineTable.vue";
import currencyMixin from "@/mixins/currencyMixin";
import initMixin from "../../initMixin";
import ListaSemanasCampanha from "./ListaSemanasCampanha";
export default {
  name: "TableMetaEquipes",

  components: {
    LineTable,
    ListaSemanasCampanha
  },

  props: {
    items: {
      type: Array
    }
  },

  mixins: [initMixin, currencyMixin],

  data() {
    return {
      expanded: [],
      singleExpand: false,
      headers: [
        { text: "Função", value: "funcao" },
        { text: "Indicador", value: "indicador" },
        { text: "Tipo de Meta", value: "tipo_meta" },
        { text: "Base de Cálculo", value: "tipo_calculo" },
        { text: "Crescimento", value: "perc_cresc" },
        { text: "Prioridade do Indicador", value: "maior_menor" },
        { text: "Semanas", value: "data-table-expand", align: "center" }
      ]
    };
  }
};
</script>

<style></style>
