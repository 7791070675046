var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"elevation-1 mb-3",attrs:{"headers":_vm.headers,"items":_vm.items,"no-data-text":"Nenhuma meta cadastrada","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id_campanha_funcao","show-expand":"","sort-by":"cod_funcao","loading-text":"Loading..."},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',[_vm._v(" Meta Semanal ")])]},proxy:true},{key:"item.funcao",fn:function(ref){
var item = ref.item;
return [_c('LineTable',{staticClass:"mt-2 mb-2",attrs:{"code":item.cod_funcao,"description":item.funcao,"segmento":item.id_band}})]}},{key:"item.indicador",fn:function(ref){
var item = ref.item;
return [_c('LineTable',{staticClass:"mt-2 mb-2",attrs:{"code":item.id_indicador,"description":item.indicador}})]}},{key:"item.perc_cresc",fn:function(ref){
var item = ref.item;
return [(item.perc_cresc)?_c('span',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-finance")]),_vm._v(" "+_vm._s(item.perc_cresc)+"% ")],1):_c('span',[_vm._v("Não possui")])]}},{key:"item.maior_menor",fn:function(ref){
var item = ref.item;
return [(item.maior_menor === '>')?_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"green"}},[_vm._v("mdi-arrow-up ")]),_vm._v(" Maior ")],1):_vm._e(),(item.maior_menor === '<')?_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"blue"}},[_vm._v("mdi-arrow-down ")]),_vm._v(" Menor ")],1):_vm._e()]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [(!isExpanded)?_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v(" expandir "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_c('v-btn',{attrs:{"color":"warning","small":""},on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v(" ocultar "),_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('ListaSemanasCampanha',{attrs:{"idCampanhaFuncao":item.id_campanha_funcao}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }