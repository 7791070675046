var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getFuncaoCampanhaProgressiva,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id_campanha_funcao","sort-by":"cod_funcao","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Metas Progressivas")]),_c('v-spacer'),_c('v-switch',{staticClass:"mt-2",attrs:{"label":"Abrir apenas um"},model:{value:(_vm.singleExpand),callback:function ($$v) {_vm.singleExpand=$$v},expression:"singleExpand"}})],1)]},proxy:true},{key:"item.funcao",fn:function(ref){
var item = ref.item;
return [_c('LineTable',{staticClass:"mt-2 mb-2",attrs:{"code":item.cod_funcao,"description":item.funcao,"segmento":item.id_band}})]}},{key:"item.indicador",fn:function(ref){
var item = ref.item;
return [_c('LineTable',{staticClass:"mt-2 mb-2",attrs:{"code":item.id_indicador,"description":item.indicador}})]}},{key:"item.valor_bonus",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_bonus))+" ")])]}},{key:"item.maior_menor",fn:function(ref){
var item = ref.item;
return [(item.maior_menor === '>')?_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"green"}},[_vm._v("mdi-arrow-up ")]),_vm._v(" Maior ")],1):_vm._e(),(item.maior_menor === '<')?_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"blue"}},[_vm._v("mdi-arrow-down ")]),_vm._v(" Menor ")],1):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.getFaixasMetaProgressiva(item.id_campanha_funcao).length <= 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi mdi-alert-circle ")])]}}],null,true)},[_c('span',[_vm._v("Metas progressivas devem ter no mínimo duas faixas")])]):_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi mdi-check")])]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v("expandir")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('lista-faixas-progressivas',{attrs:{"onEdite":_vm.onEdite,"items":_vm.getFaixasMetaProgressiva(item.id_campanha_funcao)},on:{"delete":function($event){return _vm.initialize()}}})],1)]}},(_vm.onEdite)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","x-small":"","color":"green","dark":""},on:{"click":function($event){return _vm.createMetaProgressiva(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1)]}}:null],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","retain-focus":false,"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Adicionar faixa progressiva")]),_c('v-card-text',[_c('form-meta-progressiva',{attrs:{"onEdite":_vm.onEdite,"item":_vm.itemMetaProgressiva},on:{"success":_vm.onSuccessCreateMetaProgressiva,"close":function($event){_vm.dialog = false}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }