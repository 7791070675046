<template>
  <v-dialog v-model="dialog" persistent max-width="900" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          Vincular Modelo
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="red">mdi-close</v-icon>Modelos Fora da
              Campanha
            </h4>
            <v-btn
              class="mb-3"
              color="success"
              @click="addTodosModelos"
              :disabled="modeloNaoParticipantes.length == 0"
            >
              Adicionar todos
            </v-btn>
            <draggable
              class="draggable-area exclude-companies"
              :list="modeloNaoParticipantes"
              group="modelos"
              @change="moveAdded"
            >
              <div
                class="v-list-item__content list"
                v-for="modelo in modeloNaoParticipantes"
                :key="modelo.id_produto"
              >
                <div class="list-equipe-items">
                  <div class="list-equipe">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${modelo.cod_modelo} -`
                      }}</strong>
                      {{ modelo.descricao_modelo }}
                    </v-row>
                    <BaseSegmento :segmento="modelo.id_band" />
                  </div>
                  <div class="mr-4">
                    <v-btn icon @click="addModelosParcitipantes(modelo)">
                      <v-icon color="green">mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="green">mdi-check</v-icon>Modelos
              Participantes da Campanha
            </h4>
            <v-btn
              class="mb-3"
              color="error"
              @click="removerTodosModelos"
              :disabled="modeloParticipantes.length == 0"
            >
              Remover todos
            </v-btn>
            <draggable
              class="draggable-area include-companies"
              :list="modeloParticipantes"
              group="equipes"
              @change="moveRemove"
            >
              <div
                class="v-list-item__content"
                v-for="modelo in modeloParticipantes"
                :key="modelo.id_produto"
              >
                <div class="list-equipe-participantes-items">
                  <div>
                    <v-btn
                      class="mr-4"
                      icon
                      @click="removeModelosParticipantes(modelo)"
                    >
                      <v-icon color="red">mdi-arrow-left-bold</v-icon>
                    </v-btn>
                  </div>
                  <div class="list-equipe mr-4">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${modelo.cod_modelo} -`
                      }}</strong>

                      {{ modelo.descricao_modelo }}
                    </v-row>
                    <BaseSegmento :segmento="modelo.id_band" />
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseSegmento from "@/components/shared/BaseSegmento";
import serviceCampanha from "@/services/campanha";
import draggable from "vuedraggable";

export default {
  name: "DraggableModeloCampanha",

  components: {
    draggable,
    BaseSegmento
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    campanha: {
      type: Object,
      default: () => ({})
    },

    modeloNaoParticipantes: {
      type: Array,
      default: () => []
    },

    modeloParticipantes: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    async addTodosModelos() {
      await Promise.all(
        this.modeloNaoParticipantes.map(item => {
          const form = {
            id_campanha: this.$route.params.idCampanha,
            id_modelo: item.id_produto,
            id_funcao: this.campanha.id_funcao,
            id_indicador: this.campanha.id_indicador
          };
          serviceCampanha.postCampanhaModelo(form);
          this.$notify({
            type: "success",
            text: `Modelo adicionado na campanha`
          });
          this.refreshAdd(item, form);
        })
      ).catch(erro => {
        console.log(erro);
        this.$notify({
          type: "error",
          text: "Erro ao adicionar modelos"
        });
      });
    },

    async addModelosParcitipantes(item) {
      try {
        const form = {
          id_campanha: this.$route.params.idCampanha,
          id_modelo: item.id_produto,
          id_funcao: this.campanha.id_funcao,
          id_indicador: this.campanha.id_indicador
        };
        await serviceCampanha.postCampanhaModelo(form);
        this.$notify({
          type: "success",
          text: `Modelo ${item.descricao_modelo} adicionado na campanha`
        });

        this.refreshAdd(item, form);
      } catch (error) {
        this.$notify({
          type: "error",
          text: `Erro ao adicionar Modelo`
        });
      }
    },

    async removerTodosModelos() {
      await Promise.all(
        this.modeloParticipantes.map(item => {
          const form = {
            id_campanha: this.$route.params.idCampanha,
            id_modelo: item.id_produto,
            id_funcao: this.campanha.id_funcao,
            id_indicador: this.campanha.id_indicador
          };
          serviceCampanha.deleteCampanhaModelo(item.id_campanha_modelo);
          this.$notify({
            type: "warning",
            text: `Modelo removido da campanha`
          });
          this.refreshRemove(form);
        })
      ).catch(erro => {
        console.log(erro);
        this.$notify({
          type: "error",
          text: "Erro ao remover modelos"
        });
      });
    },

    async removeModelosParticipantes(item) {
      try {
        const form = {
          id_campanha: this.$route.params.idCampanha,
          id_modelo: item.id_produto,
          id_funcao: this.campanha.id_funcao,
          id_indicador: this.campanha.id_indicador
        };

        await serviceCampanha.deleteCampanhaModelo(item.id_campanha_modelo);
        this.$notify({
          type: "warning",
          text: `Modelo ${item.descricao_modelo} removido da campanha`
        });
        this.refreshRemove(form);
      } catch (error) {
        this.$notify({
          type: "error",
          text: `Error ao remove Modelo`
        });
      }
    },

    moveAdded(evt) {
      let modelo = {};
      const { removed } = evt;

      if (removed) {
        modelo = removed.element;
        this.addModelosParcitipantes(modelo);
      }
    },

    moveRemove(evt) {
      let modelo = {};
      const { removed } = evt;

      if (removed) {
        modelo = removed.element;
        this.removeModelosParticipantes(modelo);
      }
    },

    close() {
      this.$emit("close");
    },

    refreshAdd(item, form) {
      this.modeloNaoParticipantes.splice(
        this.modeloNaoParticipantes.indexOf(item),
        1
      );
      this.$emit("atualizaModelosParticipantes", form);
    },

    refreshRemove(form) {
      this.$emit("atualizaModelosParticipantes", form);
      this.$emit("atualizaModelosNaoParticipantes", form);
    }
  }
};
</script>

<style scoped>
.v-list-item__content {
  padding-inline-start: 15px;
  border: 1px solid hsla(0, 0%, 31%, 0.199);
  border-radius: 10px;
  margin-bottom: 4px;
}

.draggable-area {
  min-height: 300px;
  padding: 8px;
}

.draggable-area.include-companies {
  border: 2px dashed hsla(128, 100%, 34%, 0.39);
  height: 55vh;
  overflow: auto;
}

.draggable-area.include-companies::-webkit-scrollbar-track {
  background-color: #adf3af;
}
.draggable-area.include-companies::-webkit-scrollbar {
  width: 6px;
}
.draggable-area.include-companies::-webkit-scrollbar-thumb {
  background: #2a772c;
}

.draggable-area.exclude-companies {
  border: 2px dashed hsla(347, 100%, 34%, 0.39);
  height: 55vh;
  overflow: auto;
}

.draggable-area.exclude-companies::-webkit-scrollbar-track {
  background-color: #e7b8b8;
}
.draggable-area.exclude-companies::-webkit-scrollbar {
  width: 6px;
}
.draggable-area.exclude-companies::-webkit-scrollbar-thumb {
  background: #9b3232;
}

.v-list-item__content .list {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-items {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-participantes-items {
  display: flex !important;
}

.list-equipe {
  display: flex !important;
  flex-direction: column;
}
</style>
